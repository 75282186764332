































































































import {
  ArchivesFiltersValue,
  SelectFilter,
} from "@/models/utils/archives-filters.interface";
import { CBtn } from "@/models/utils/c-btn.interface";
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

@Component
export default class ArchivedSelectFilters extends Vue {
  @Prop({ required: false, default: null, type: Object })
  private filters: SelectFilter;
  @PropSync("selected", { required: true, type: Object })
  private _selected: ArchivesFiltersValue;

  private disabled = true;

  private modifyBtn: Omit<CBtn, "type"> = {
    btnClass: "",
    label: "Modifica",
    labelClass: "c-label-dark",
    color: "var(--v-primary-lighten5)",
    icon: null,
  };

  private onFilerClick(): void {
    this.disabled = true;
    this.$emit("on-filter");
  }
}
